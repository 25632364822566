import { render } from "./DashboardConfigurationCard.vue?vue&type=template&id=0787fafd"
import script from "./DashboardConfigurationCard.vue?vue&type=script&lang=js"
export * from "./DashboardConfigurationCard.vue?vue&type=script&lang=js"

import "./DashboardConfigurationCard.vue?vue&type=style&index=0&id=0787fafd&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0787fafd"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0787fafd', script)) {
    api.reload('0787fafd', script)
  }
  
  module.hot.accept("./DashboardConfigurationCard.vue?vue&type=template&id=0787fafd", () => {
    api.rerender('0787fafd', render)
  })

}

script.__file = "src/components/dashboard/DashboardConfigurationCard.vue"

export default script